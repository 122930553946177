<template>
  <div style="padding: 0 3%;margin-top: 5%;">
    <el-row class="userInfo_list">
      <div class="grid-content">
        <el-col :span="12">
          <div class="lost-title">登陆账号：{{ userInfo.userAccount }}</div>
        </el-col>
        <el-col :span="12">
          <div class="lost-title">注册手机号：{{ userInfo.contactPhone }}</div>
        </el-col>
      </div>
    </el-row>
    <div class="grey-line">
      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">公司名称:</div>
            <div class="title-content">{{ userInfo.unitName }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">公司通讯地址:</div>
            <div class="title-content">{{ userInfo.unitAddress }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">公司所在地:</div>
            <div class="title-content">{{ userInfo.unitRegion }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">公司注册地址:</div>
            <div class="title-content">{{ userInfo.unitRegisterAddress }}</div>
          </div>
        </el-col>
      </el-row>
	  
	  <el-row>
	    <el-col :span="12">
	      <div class="grid-content">
	        <div class="complaint-title">公司税号:</div>
	        <div class="title-content">{{ userInfo.dutyNumber }}</div>
	      </div>
	    </el-col>
		<el-col :span="12">
		  <div class="grid-content">
		    <div class="complaint-title">公司电话:</div>
		    <div class="title-content">{{ userInfo.unitTel }}</div>
		  </div>
		</el-col>
	  </el-row>

      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">联系人:</div>
            <div class="title-content">{{ userInfo.contactName }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">联系电话:</div>
            <div class="title-content">{{ userInfo.contactWay }}</div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">法人姓名:</div>
            <div class="title-content">{{ userInfo.legalPersonName }}</div>
          </div>
        </el-col>
	  </el-row>
	  <el-row>
		  <el-col :span="12">
			<div class="grid-content">
			  <div class="complaint-title">法人联系方式:</div>
			  <div class="title-content">{{ userInfo.legalPersonMobile }}</div>
			</div>
		  </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">法人身份证:</div>
            <div class="title-content">{{ userInfo.legalPersonIdentity }}</div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">法人身份证正面:</div>
            <div class="title-content">
              <el-image v-if="userInfo.legalPersonPhoto"
                        style="width: 50px; height: 50px"
                        :src="userInfo.legalPersonPhoto"
                        :preview-src-list="[userInfo.legalPersonPhoto]"></el-image>
            </div>
          </div>
        </el-col>
		<el-col :span="12">
		  <div class="grid-content">
		    <div class="complaint-title">法人身份证反面:</div>
		    <div class="title-content">
		      <el-image v-if="userInfo.legalPersonPhotoVerso"
		                style="width: 50px; height: 50px"
		                :src="userInfo.legalPersonPhotoVerso"
		                :preview-src-list="[userInfo.legalPersonPhotoVerso]"></el-image>
		    </div>
		  </div>
		</el-col>
      </el-row>


      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">开户行:</div>
            <div class="title-content">{{ userInfo.bankName }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">开户行账号:</div>
            <div class="title-content">{{ userInfo.bankAccount }}</div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">收款账号开户行:</div>
            <div class="title-content">{{ userInfo.payeeBankName }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">收款账号:</div>
            <div class="title-content">{{ userInfo.payeeBankAccount }}</div>
          </div>
        </el-col>
      </el-row>


      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">营业执照:</div>
            <div class="title-content">
              <el-image v-if="userInfo.businessLicense"
                        style="width: 50px; height: 50px"
                        :src="userInfo.businessLicense"
                        :preview-src-list="[userInfo.businessLicense]"></el-image>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">道路许可证:</div>
            <div class="title-content">
              <el-image v-if="userInfo.roadPermit"
                        style="width: 50px; height: 50px"
                        :src="userInfo.roadPermit"
                        :preview-src-list="[userInfo.roadPermit]"></el-image>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="margin-top: 10px;">
        <el-button type="text" style="border-bottom: 1px solid;" @click="$router.push({name: 'userOther'})">查看更新申请资质
        </el-button>
      </div>
      <!-- <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">是否同意条款:</div>
            <div class="title-content">
              <span v-if="userInfo.isAgree==1">同意</span>
              <span v-if="userInfo.isAgree==0">不同意</span>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="complaint-title">账号是否有效:</div>
            <div class="title-content">
              <span v-if="userInfo.isValid==1">有效</span>
              <span v-if="userInfo.isValid==0">无效</span>
            </div>
          </div>
        </el-col>
      </el-row> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'userDetail',
  // props: ['userInfo'],
  data() {
    return {
		userInfo:{},
	}
  },
  created() {
	  this.initUserInfo();
  },
  methods: {
	  initUserInfo: function () {
	    this.HTTP.get('user/detail').then(res => {
	      if (res.data.code == 1) {
	        this.userInfo = res.data.data;
	      } else {
	        this.$message.error(res.data.message);
	      }
	    }).catch(err => {
	      this.$message.error(err);
	    })
	  },
  }
};
</script>

<style scoped>
.grey-line .el-row {
  border-bottom: 1px solid #dcdfe6;
}

.grey-line .el-row:last-child {
  border-bottom: none;
}

.userInfo_list .grid-content {
  line-height: 20px;
}

.userInfo_list .lost-title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
}

.userInfo_list .title-content {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 20px;
  margin-left: 5px;
}

.grid-content {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.complaint-title {
  align-items: center;
  margin-right: 5px;
  font-size: 16px;
  display: flex;
  font-weight: bolder;
  color: #1f2f3d;
  white-space: nowrap;
}

.title-content {
  display: flex;
  align-items: center;
}
</style>
